import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Icon1 from '../../assets/slider_icon1.png'
import Icon2 from '../../assets/slider_icon2.png'
import Icon3 from '../../assets/slider_icon3.png'
import Icon4 from '../../assets/slider_icon4.png'
import './WeCard.css'

const WeCard = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: 'linear',
        arrows: false,
        responsive: [
            {
              breakpoint: 1424,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
              },
            },
      
            {
              breakpoint: 1124,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
              },
            },
            {
                breakpoint: 680,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2,
                },
              },
          ],
        };
    
  return (
    <Slider {...settings}>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon1} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>sales ready website</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon2} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>mobile applications</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon3} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>digital marketing</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon4} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>CRM Implementation</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon1} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>sales ready website</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon2} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>mobile applications</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon3} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>sales ready website</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
    <div className="card-wrapper">
        <div className="card">
            <div className="card-image">
                <img src={Icon4} alt="" />
            </div>
            <div className="card-divider"></div>
            <div className="card-heading">
                <h1>sales ready website</h1>
            </div>
            <div className="card-p">
                <p>Solve problems or add more value by introducing mobile apps.</p>
            </div>
            <div className="read">
                <span>Read more</span>
            </div>
        </div>
    </div>
</Slider>
  )
}

export default WeCard
