import React ,{ forwardRef } from 'react'
import './VideoCarousel.css'

import {makeStyles, withStyles} from '@material-ui/core/styles'
import  Grid  from '@material-ui/core/Grid'
import  Typography  from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FastRewindIcon  from '@material-ui/icons/FastRewind'
import FastForwardIcon  from '@material-ui/icons/FastForward'
import PlayArrowIcon  from '@material-ui/icons/PlayArrow'
import PauseIcon  from '@material-ui/icons/Pause'
import Slider from '@material-ui/core/Slider'
import Tooltip  from '@material-ui/core/Tooltip'
import VolumeUp from '@material-ui/icons/VolumeUp'
import VolumeOff from '@material-ui/icons/VolumeOff'
import Button from '@material-ui/core/Button'
import FullScreenIcon from '@material-ui/icons/Fullscreen'
import Popover from '@material-ui/core/Popover'




const useStyles = makeStyles({
    

    controlsWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1,
        overflow: 'hidden'
    },

    controlIcons: {
        color: '#777',
        fontSize: 50,
        transform: 'scale(0.9)',
        '&:hover': {
            color: '#fff',
            transform: 'scale(1)',
        }
    },

    bottomIcons: {
        color: 'var(--themeColor)',
        '&:hover':{
            color: '#fff',
        }
    },
    volumeSlider:{
        color: 'var(--themeColor)',
        width: 100,
    }
});

function ValueLabelComponent(props){
    const {children, open, value} = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement='0' title={value}>
            {children}
        </Tooltip>
    )
}

const PrettoSlider = withStyles({
    root: {
        color: 'var(--themeColor)',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid var(--themeColor)',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

export default forwardRef(({
    onPlayPause, 
    playing,
    onRewind,
    onFastForward,
    muted,
    onMute,
    onVolumechange,
    onVolumeSeekUp,
    volume,
    onPlaybackRateChange,
    playbackRate,
    onToggleFullScreen,
    played,
    onSeekMouseDown,
    onSeek,
    onSeekMouseUp,
    elapsedTime,
    totalDuration,
    onChangeDisplayFormat,
    },ref) => {

    const classes = useStyles();

    const [anchorE1, setAnchorE1] = React.useState(null);

    const handlePopover = (event) => {
        setAnchorE1(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorE1(null)
    };

    const open = Boolean(anchorE1);
    const id = open ? 'playbackrate-popover' : undefined;
    
    return(
    <div className={classes.controlsWrapper} ref={ref}>

            {/* Top Controls */}
            <Grid
            container direction='row'
            alignItems='center'
            justifyContent='space-between'
            style={{padding: 16}}
            >
                <Grid item>
                    <Typography variant='h5'
                    style={{color: '#fff'}}
                    >Video Title</Typography>
                </Grid>
                </Grid>

                {/* middle controls */}
                <Grid container direction='row' alignItems='center' justify='center'>
                    <IconButton
                    onClick={onRewind}
                    className={classes.controlIcons}
                    aria-label='reqind'
                    >
                        <FastRewindIcon fontSize='inherit'/>
                    </IconButton>

                    {/* play button */}
                    <IconButton
                    onClick={onPlayPause}
                    className={classes.controlIcons}
                    aria-label='reqind'
                    >
                        {playing? (<PauseIcon fontSize='inherit'/>) : 
                        (<PlayArrowIcon fontSize='inherit'/>)}
                    </IconButton>

                    <IconButton
                    onClick={onFastForward}
                    className={classes.controlIcons}
                    aria-label='reqind'
                    >
                        <FastForwardIcon fontSize='inherit'/>
                    </IconButton>
                </Grid>
                {/* bottom controls */}
                <Grid container direction="row"
                justify='space-between'
                alignItems='center'
                style={{padding: 16}}>
                    <Grid item xs={12}>
                        <PrettoSlider
                        min= {0}
                        max={100}
                        value={played * 100}
                        ValueLabelComponent={(props) => <ValueLabelComponent {...props} value={elapsedTime}/>}
                        onChange={onSeek}
                        onMouseDown={onSeekMouseDown}
                        onChangeCommitted={onSeekMouseUp}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container alignItems='center' direction='row'>
                            <IconButton 
                            onClick={onPlayPause}
                            className={classes.bottomIcon}>
                                {playing?(<PauseIcon fontSize='large'
                                style={{color: 'var(--themeColor)'}}/>) 
                                : 
                                (<PlayArrowIcon 
                                fontSize= 'large'
                                style={{color: 'var(--themeColor)'}}/>)}
                            </IconButton>

                            {/* Volume up */}

                            <IconButton 
                            onClick={onMute}
                            className={classes.bottomIcon}>
                               {muted?
                               (<VolumeOff fontSize= 'large'/>) 
                               :
                               (<VolumeUp 
                                fontSize= 'large'
                                style={{color: 'var(--themeColor)'}}/>)}
                            </IconButton>

                            <Slider
                            min={0}
                            max={100}
                            value={volume * 100}
                            className={classes.volumeSlider}
                            onChange={onVolumechange}
                            onChangeCommitted={onVolumeSeekUp}
                            />
                            <Button 
                            onClick={onChangeDisplayFormat}
                            variant='text' 
                            style={{color: '#fff', marginLeft: 16}}>
                                <Typography>
                                    {elapsedTime}/{totalDuration}
                                </Typography>
                            </Button>
                        </Grid>

                        
                    </Grid>
                    <Grid item>
                            <Button 
                            onClick={handlePopover}
                            className={classes.bottomIcons}
                            variant='text'>
                                <Typography>{playbackRate}X</Typography>
                            </Button> 

                            <Popover
                            id={id}
                            open={open}
                            anchorE1={anchorE1}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            >
                                <Grid container direction='column-reverse'>
                               {[0.5,1,1.5,2].map((rate) => (
                               <Button 
                               onClick={() => onPlaybackRateChange(rate)}
                               variant='text'>
                                    <Typography
                                    color={rate===playbackRate? 'var(--themeColor)':'black'}
                                    >{rate}</Typography>
                                </Button>
                                ))}
                                </Grid>
                            </Popover>
                            <IconButton
                            onClick={onToggleFullScreen}
                            className={classes.bottomIcons}
                            >
                                <FullScreenIcon
                                fontSize='large'
                                />
                            </IconButton>
                        </Grid>
                </Grid>
        </div>
)})