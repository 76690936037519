import React from "react";
import Digitally from "./components/Digitally/Digitally";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Technology from "./components/Technology/Technology";
import Testimonial from "./components/Testimonial/Testimonial";
import WhatWe from "./components/WhatWe/WhatWe";
import WhyChooseUs from "./components/WhyChooseUs/WhyChooseUs";

function App() {
  return (
    <div>
      <Navbar/>
      <Hero/>
      <Testimonial/>
      <WhatWe/>
      <Technology/>
      <WhyChooseUs/>
      <Digitally/>
      <Footer/>
    </div>
  );
}

export default App;
