import React  from 'react'
import './VideoCarousel.css'
import ReactPlayer from 'react-player'
import {makeStyles} from '@material-ui/core/styles'
import { useState, useRef } from 'react'
import PlayerControls from './PlayerControl'
import screenfull from 'screenfull'

const useStyles = makeStyles({
    playerWrapper: {
        width: '100%',
        position: 'relative'
    },
});

const format = (seconds) => {
    if(isNaN(seconds)){
        return '00:00'
    }

    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = date.getUTCSeconds().toString().padStart(2,'0')
    if(hh){
        return `${hh}: ${mm.toString().padStart(2,'0')}:${ss}`
    }

    return `${mm}:${ss}`
}

let count = 0

const VideoCarousel = ({url}) => {
    

    const playerRef = useRef(null)
    const playerContainerRef = useRef(null)
    const controlsRef = useRef(null)

    const [state, setState] = useState({
        playing: false,
        muted: true,
        volume: 0.5,
        playbackRate: 1.0,
        played: 0,
        seeking: false,
    })

    const [timeDisplayFormat, setTimeDisplayFormat] = useState('normal')

    const classes = useStyles();

    const {playing, muted, volume, playbackRate, played,seeking} = state 

    const handlePlayPause = () => {
        setState({...state, playing: !state.playing});
    };

    const handleRewind= () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    }

    const handleFastforward= () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
    }

    const handleMute = () => {
        setState({...state, muted: !state.muted})
    }

    const handleVolumeChange=(e,newValue) => {
        setState({...state, 
            volume: parseFloat(newValue / 100), 
            muted: newValue === 0 ? true : false,
    });
    };

    const handleVolumeSeekUp=(e,newValue) => {
        setState({...state, 
            volume: parseFloat(newValue / 100), 
            muted: newValue === 0 ? true : false,
    });
    }

    const handlePlaybackRateChange = (rate) => {
        setState({...state, playbackRate: rate})
    }

    const toggleFullScreen = () => {
        screenfull.toggle(playerContainerRef.current)
    }

    const handleProgress = (changeState) => {
        console.log(changeState)

        if(count>3){
            controlsRef.current.style.visiblity = 'hidden';
            count = 0
        }

        if(controlsRef.current.style.visiblity === 'visible'){
            count+=1
        }

        if(!seeking){
        setState({...state, ...changeState})
        }
    }

    const handleSeekChange = (e,newValue) => {
        setState({...state, played: parseFloat(newValue / 100)})
    }

    const handleSeekMouseDown= (e) => {
        setState({...state, seeking: true})
    }

    const handleSeekMouseUp = (e, newValue) => {
        setState({...state, seeking: false})
        playerRef.current.seekTo(newValue / 100)
    }

    const handleChangeDisplayFormat = () => {
        setTimeDisplayFormat( timeDisplayFormat 
            === 
            'normal'
            ?
            'remaing'
            : 
            'normal');
    }

    const handleMouseMove = () => {
        controlsRef.current.style.visiblity = 'visible';
        count = 0
    }

    const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00'
    const duration = playerRef.current? playerRef.current.getDuration() : '00:00';

    const elapsedTime = timeDisplayFormat === 'normal' ?
    format(currentTime) 
    :
    `-${format(duration - currentTime)}`
    const totalDuration = format(duration)

  return (
    <div className="video-carousel">
       <div 
       ref={playerContainerRef} 
       className={classes.playerWrapper}
       onMouseMove={handleMouseMove}
       >
       <ReactPlayer
       ref={playerRef}
       width={'100%'}
       height= '100%'
        url={url}
        muted= {muted}
        playing= {playing}
        volume={volume}
        playbackRate={playbackRate}
        onProgress={handleProgress}
        />
        <PlayerControls
        ref={controlsRef}
        onPlayPause= {handlePlayPause}
        playing={playing}
        onRewind={handleRewind}
        onFastForward={handleFastforward}
        muted={muted}
        onMute={handleMute}
        onVolumechange={handleVolumeChange}
        onVolumeSeekUp={handleVolumeSeekUp}
        volume={volume}
        playbackRate={playbackRate}
        onPlaybackRateChange={handlePlaybackRateChange}
        onToggleFullScreen = {toggleFullScreen}
        played={played}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        elapsedTime={elapsedTime}
        totalDuration={totalDuration}
        onChangeDisplayFormat = {handleChangeDisplayFormat}
        />
       </div>
    </div>
  )
}

export default VideoCarousel