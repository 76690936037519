import React from 'react'
import './OurWork.css'
import CarTrustLogo from '../../assets/carTrust-logo.png'
import TopArrow from '../../assets/arrow_top.png'
import BottomArrow from '../../assets/arrow_bottom.png'
import CaseStudy from '../../assets/case_study_img3.png'

const OurWork = () => {

  return (
    <div className="our-work">
        <div className="our-left">
            <div className="our-heading">
                <h1>some of our work</h1>
                <div className="our-divider"></div>
            </div>
            <div className="our-content">
                <h1>1. sol beach resort</h1>
                <p>The tablet modes have changed the modern look of the app and its usages.
                    Just like that we created a Partners Application for CarTrust one of the leading
                    Certified Pre-Owned Vehicle Inscpetion Company in Saudi Arabia
                </p>
                <div className="points">
                    <div className="our-dots"></div>
                    <div className="our-text">
                        <span>Top Performance</span>
                    </div>
                </div>
                <div className="points">
                    <div className="our-dots"></div>
                    <div className="our-text">
                        <span>Security</span>
                    </div>
                </div>
                <div className="our-logo">
                    <img src={CarTrustLogo} alt="" />
                </div>
                <button>Read full case study</button>
            </div>
        </div>
        <div className="our-right">
            <div className="our-below-content">
            <div className="our-dots-and-arrows">
                <div className="our-arrows">
                    <img src={TopArrow} alt="" />
                    <img src={BottomArrow} alt="" />
                </div>
                <div className="our-right-dots">
                    
                    <div className="bullet-content">
                        <h4><div className="bullet-dots"/>SOL Beach Resort</h4>
                        <h4><div className="bullet-dots"/>Car Trust Partners App</h4>
                        <h4><div className="bullet-dots"/>Car Trust Mobile App</h4>
                        <h4><div className="bullet-dots"/>Car Trust Website</h4>
                        <h4><div className="bullet-dots"/>Zahr Website</h4>
                        <h4><div className="bullet-dots"/>Abazeer Website</h4>
                    </div>
                </div>
                <div className="our-container"></div>
                <div className="our-display">
                    <img src={CaseStudy} alt="" />
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default OurWork