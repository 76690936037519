import React from 'react'
import './DigitallyEarth.css'
import Earth from '../../assets/btm_bg.png'

const DigitallyEarth = () => {
  return (
    <div className="btm-bg">
        <img src={Earth} alt="" />
    </div>
  )
}

export default DigitallyEarth
