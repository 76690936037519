import React, { useState } from 'react'
import './Navbar.css'
import logo from '../../assets/logo.png'
import {FaBars, FaTimes} from 'react-icons/fa'
import {FaTwitter, FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa'
import TopIcon from '../../assets/top_icon.png'

const Navbar = () => {

    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)

  return (
    <div className="navbar">
        <div className="logo">
            <img src={logo} alt="" />
            <ul className={click? "nav-menu active" : "nav-menu"}>
                <li className="nav-items">
                    <a href="/">Home</a>
                </li>
                <li className="nav-items">
                    <a href="/">About</a>
                </li>
                <li className="nav-items">
                    <a href="/">Testimonial</a>
                </li>
                <li className="nav-items">
                    <a href="/">Contact</a>
                </li>
            </ul>

            <div className="hamburger" onClick={handleClick}>
                {!click? <FaBars size={25} style={{color: 'var(--white)'}}/>
                : <FaTimes size={25} style={{color: 'var(--white)'}}/>}
            </div>
        </div>
        <div className="navbar-social">
        <a href="/"><li className='twitter'><i><FaTwitter/></i></li></a>
        <a href="/"><li className='facebook'><i><FaFacebook/></i></li></a>
        <a href="/"><li className='instagram'><i><FaInstagram/></i></li></a>
        <a href="/"><li className='linkedin'><i><FaLinkedin/></i></li></a>
        <div className="navbar-divider"></div>
            <div className="navbar-follow">
                <h3>follow us</h3>
            </div>
            <div className="top-icon">
                <img src={TopIcon} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Navbar
