import React from 'react'
import './Footer.css'
import Logo from '../../assets/logo.png'

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-divider"></div>
        <div className="footer-content">
            <div className="f-first-content">
                <img src={Logo} alt="" />
                <h3>address</h3>
                <div className="content-divider1"></div>
                <span>793D Block C, Faisal Town, Lahore, Pakistan</span>
                <span>Awtad Commercial center, Al Azizziyah, Jeddah</span>
            </div>
            <div className="second-content">
                <h3>other ways to connect</h3>
                <span>You can also reach us using the following:</span>
                <div className="content-divider2"></div>
                <a href="/">ahmad@sohoby.com</a>
                <span>WhatsApp</span>
                <a href="/">+923337438823</a>
            </div>
            <div className="third-content">
                <h3>ideas in mind?</h3>
                <button>Let's discuss your project</button>
                <div className="social"></div>
            </div>
        </div>
        <div className="bottom-divider-footer"></div>
        <div className="bottom-span">
            <span>&copy; 2022 sohoby information technology company. all rights reserved.</span>
        </div>
    </div>
  )
}

export default Footer