import React from 'react'
import './Technology.css'
import Apple from '../../assets/platforms_icon4.png'
import Globe from '../../assets/platforms_icon7.png'
import Android from '../../assets/platforms_icon1.png'
import IOS from '../../assets/platforms_icon2.png'
import Google from '../../assets/platforms_icon3.png'
import Linus from '../../assets/platforms_icon8.png'
import ReactJS from '../../assets/platforms_icon6.png'
import ReactNative from '../../assets/platforms_icon5.png'

const Technology = () => {
  return (
    <div className="technology">
       <div className="technology-container">
       <div className="t-heading">
            <h1>technology we use</h1>
            <div className="t-divider"></div>
        </div>
        <div className="t-mobile">
        <div className="t-images">
            <div className="apple">
            <img src={Apple} alt="" />
            <h1>Apple</h1>
            </div>
            <div className="web">
            <img src={Globe} alt="" />
            <h1>Web</h1>
            </div>
            <div className="android">
            <img src={Android} alt="" />
            <h1>Android</h1>
            </div>
            <div className="ios">
            <img src={IOS} alt="" />
            <h1>IOS</h1>
            </div>
            <div className="google">
            <img src={Google} alt="" />
            <h1>Google</h1>
            </div>
        </div>
        <div className="bottom-images">
        <div className="linus">
            <img src={Linus} alt="" />
            <h1>Linus</h1>
            </div>
            <div className="reactjs">
            <img src={ReactJS} alt="" />
            <h1>React JS</h1>
            </div>
            <div className="reactnative">
            <img src={ReactNative} alt="" />
            <h1>React Native</h1>
            </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default Technology