import React from 'react'
import './Digitally.css'
import Map from '../../assets/map.png'
import DigitallyEarth from '../DigitallyEarth/DigitallyEarth'

const Digitally = () => {
  return (
    <div className="digitally">
       <div className="digitally-container">
       <div className="map">
            <img src={Map} alt="" />
        </div>
        <div className="end">
            <span>digitally</span>
            <span>transforming the kingdom</span>
        </div>
        <DigitallyEarth/>
       </div>
       
    </div>
  )
}

export default Digitally