import React from 'react'
import OurWork from '../OurWork/OurWork'
import WeCard from '../WeCard/WeCard'
import './WhatWe.css'
import Wave from '../../assets/blue_bar.png'

const WhatWe = () => {
  return (
    <div className="whatwe">
        <div className="whatwe-container">
            <div className="w-blur1"></div>
            <div className="w-blur2"></div>
            <div className="whatwe-heading">
                <div className="we-heading">
                    <h1>what we are best at</h1>
                    <div className="we-divider"></div>
                </div>
                <div className="we-p">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>
            <div className="lottie">
                <img src={Wave} alt="" />
            </div>
            <div className="slider">
                <WeCard/>
                
            </div>

            <div className="we-work">
            <OurWork/>
            </div>
        </div>
    </div>
  )
}

export default WhatWe
