import React from 'react'
import './WhyChooseUs.css'
import Haweek from '../../assets/logo_1.png'
import Borgat from '../../assets/logo_2.png'
import Nada from '../../assets/logo_3.png'
import MBSC from '../../assets/logo_4.png'
import SOL from '../../assets/logo_5.png'
import CarTrust from '../../assets/logo_6.png'
import Brain from '../../assets/brain_img.png'
import Newsletter from '../Newsletter/Newsletter'

const WhyChooseUs = () => {
  return (
    <div className="whychooseus">
        <div className="why-us-container">
        <div className="why-heading">
            <h1>Why choose us?</h1>
            <div className="why-divider"></div>
        </div>
        <div className="why-images">
            <div className="haweek">
            <img src={Haweek} alt="" />
            </div>
            <div className="borgat">
            <img src={Borgat} alt="" />
            </div>
            <div className="nada">
            <img src={Nada} alt="" />
            </div>
            <div className="mbsc">
            <img src={MBSC} alt="" />
            </div>
            <div className="sol">
            <img src={SOL} alt="" />
            </div>
            <div className="why-cartrust">
            <img src={CarTrust} alt="" />
            </div>
        </div>
        <div className="why-ideas">
            <div className="why-heading2">
                <h1>ideas in <span>mind?</span></h1>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form alteration in some form.</p>
            </div>
            <div className="why-brainimg">
                <img src={Brain} alt="" />
            </div>
        </div>
        <div className="why-btn">
            <button>Let's discuss your project</button>
        </div>
        </div>
        <div className="whychoose-news">
            <Newsletter/>
        </div>
    </div>
  )
}

export default WhyChooseUs