import React from 'react'
import './CloudsBg.css'
import BGClouds1 from '../../assets/clouds_dark.png'

const CloudsBg = () => {
  return (
    <div className="cloudsbg">
        <img src={BGClouds1} alt="" />
    </div>
  )
}

export default CloudsBg