import React from 'react'
import './Testimonial.css'
import Vid3 from '../../Videos/placeholder-video.mp4'
import VideoCarousel from '../VideoCarousel/VideoCarousel'
import leftArrow from '../../assets/arrow_left.png'
import rightArrow from '../../assets/arrow_right.png'
import Quotes from '../../assets/quotes_icon.png'

const Testimonial = () => {

    let box = document.querySelector('.t-video')

  const btnpressprev = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
  }

  const btnpressnext = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
  }

  return (
    <div className="testimonial">
      
        <div className="t-content">
            <div className="t-heading">
                <h2>hear from our clients</h2>
                <div className="t-divider"></div>
            </div>
         
            <div className="t-video">
                <VideoCarousel
                url={Vid3}/>
                <VideoCarousel
                url={Vid3}/>
                <VideoCarousel
                url={Vid3}/>
                {/* <VideoPlayer
                url={Vid1}/> */}
                {/* <video controls width='60%'>
                    <source src='/Videos/gym.mp4' type='video/mp4'/>
                </video>
                
                
                <video controls width='60%'>
                    <source src='/Videos/gym.mp4' type='video/mp4'/>
                </video>
                
                
                <video controls width='60%'>
                    <source src='/Videos/gym.mp4' type='video/mp4'/>
                </video>
                 */}
                
            </div>


            <div className="tt-content">
            <div className="t-quotes">
                <img src={Quotes} alt="" />
            </div>
            <div className="t-title">
            <p>"There are many variations of passages Lorem ipsum available, but the 
                majority have suffered alteration in some form, by injected humour,"
            </p>
                <h3>Abdul majeed gm at sol beach resort</h3>
                
                <h5>Read full case study</h5>
            </div>
        </div>
        <div className="arrow">
            <img src={leftArrow} alt="" onClick={btnpressprev}/>
            <img src={rightArrow} alt="" onClick={btnpressnext}/>
        </div>
        <div className="view">
            <button>View All</button>
        </div>
            
        </div>
        
    </div>
  )
}

export default Testimonial