import React from 'react'
import leftCloud from '../../assets/clouds_img_lft.png'
import RightCloud from '../../assets/clouds_img_rgt.png'
import './Clouds.css'
import { Animator, batch, MoveOut, ScrollContainer, ScrollPage, Sticky } from 'react-scroll-motion'

const Clouds = () => {
  return (
    <div className="clouds">
        <div className="left-cloud">
            <ScrollContainer>
                <ScrollPage
                page={0}>
                    <Animator
                    animation={batch(Sticky(45, 50), MoveOut(-700,-200))}>
            <img src={leftCloud} alt="" />
            </Animator>
            </ScrollPage>
            </ScrollContainer>
        </div>
        <div className="right-cloud">
            <ScrollContainer>
                <ScrollPage
                page={0}>
                    <Animator
                    animation={batch(Sticky(), MoveOut(1000, -500))}>
            <img src={RightCloud} alt="" />
            </Animator>
            </ScrollPage>
            </ScrollContainer>
        </div>
    </div>
  )
}

export default Clouds
