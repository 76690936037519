import React from 'react'
import './Hero.css'
import Mountain from '../Mountain/Mountain'
import { Animator, batch, Fade, MoveOut, ScrollContainer, ScrollPage, Sticky } from 'react-scroll-motion'
import Clouds from '../Clouds/Clouds'
import ArrowImage from '../../assets/arrows_icon.png'
import CMS from '../CMS/CMS'

const Hero = () => {
  return (
    <div className="hero">
        <div className="hero-mountain">
           <Mountain/>
        </div>
        <div className="hero-container">
            <div className="hero-content">
            <ScrollContainer>
                <ScrollPage
                page={0}
                >
                    <Animator
                    animation={batch(Sticky(30,50), Fade(), MoveOut(0,-200))}>
                <div className="premier">
                    <h1>premier</h1>
                    <h1>digital</h1>
                    <h1>solution partner</h1>
                </div>
                <button>Let's discuss your project</button>
                <div className="ammar">
                    <h3>ammar toonsi</h3>
                    <h3>managing director car trust</h3>
                    <div className="hero-divider"></div>
                    <p>"Lorem ipsum dolor mit emet, cockroacha adispence</p>
                </div>
                </Animator>
                </ScrollPage>
                </ScrollContainer>

                <div className="hero-cloud">
                    <Clouds/>
                </div>
              
            </div>
            
        </div>
        <div className="explore">
                <h3>Explore now</h3>
                <img src={ArrowImage} alt="" />
        </div>
        <div className="hero-cms">
            <CMS/>
        </div>
    </div>
  )
}

export default Hero
